import { closeSlideoverOnMouseover, handleSlideoverChange } from '#core/utils/nav'
import { withTrailingSlash } from 'ufo'

export const topLevelMenu = computed(() => {
  const runtimeConfig = useRuntimeConfig()
  const auth = useAuth()
  return [
    [
      {
        icon: 'i-heroicons-home',
        to: auth.dashboardPath,
        label: 'Home',
        on: closeSlideoverOnMouseover,
      },
      {
        icon: 'i-forgd-tools',
        label: 'Tools & Services',
        key: 'tools-and-services',
        on: {
          mouseenter(event) {
            handleSlideoverChange({
              event,
              slideover: 'tools-and-services',
            })
          },
        },
      },
      {
        icon: 'i-heroicons-academic-cap',
        label: 'Forgd Academy',
        key: 'forgd-academy',
        on: {
          mouseenter(event) {
            handleSlideoverChange({
              event,
              slideover: 'forgd-academy',
            })
          },
        },
      },
      {
        label: 'Schedule a Consultation',
        to: runtimeConfig.public.consultationUrl,
        external: true,
        icon: 'i-heroicons-calendar-days',
        on: closeSlideoverOnMouseover,
      },
    ],
    [
      {
        icon: 'i-heroicons-information-circle',
        to: 'https://forgd.com/about',
        label: 'About Forgd',
        external: true,
        on: closeSlideoverOnMouseover,
      },
      {
        icon: 'i-heroicons-chat-bubble-oval-left-ellipsis',
        to: '/support',
        label: 'Support',
        on: closeSlideoverOnMouseover,
      },
      {
        icon: 'i-heroicons-cog-8-tooth',
        to: '/settings',
        label: 'Account Settings',
        on: closeSlideoverOnMouseover,
      },
      {
        icon: 'i-heroicons-arrow-right-start-on-rectangle',
        label: 'Logout',
        loading: isLoggingOut.value,
        testId: 'logout',
        on: {
          click: logout,
          ...closeSlideoverOnMouseover,
        },
      },
    ],
  ] satisfies NavLink[][]
})

export function isCurrent({ to, key }: NavLink, strict: boolean = false) {
  const route = useRoute()
  // we just care about the first path segment matching
  const pathSegments = (to || '').split('/').filter(Boolean)
  const currPathSegments = (route.path || '').split('/').filter(Boolean)
  if (!strict) {
    if (withTrailingSlash(currPathSegments[0]) === withTrailingSlash(pathSegments[0])) {
      return true
    }
    if (key === 'forgd-academy') {
      return route.path.startsWith('/academy')
    }
  }
  // special logic, we match the top-level category which is the first 3 segments
  if (route.path.startsWith('/academy')) {
    const categoryPath = currPathSegments.slice(0, 3).join('/')
    return withTrailingSlash(categoryPath) === withTrailingSlash(pathSegments.slice(0, 3).join('/'))
  }
  // requires full match
  return withTrailingSlash(to) === withTrailingSlash(route.path)
}

export const preTgeNav: NavLink[] = [
  {
    label: 'Tokenomics & Protocol',
    icon: 'i-heroicons-cube',
    children: [
      { label: 'Token Designer', to: '/token-designer' },
      { label: 'Token Unlocks', to: '/token-unlocks' },
      { label: 'Smart Contract Development', to: '/partner-activity-lead/pre-tge-smart-contract-protocol' },
      { label: 'Token Creation & Mint', to: '/partner-schedule/magna' },
      { label: 'Airdrop & Token Streaming', to: '/partner-schedule/magna' },
      { label: 'Multi-Sig Token Custody', to: '/partner-schedule/squads' },
    ],
  },
  {
    label: 'Finance and Fundraising',
    icon: 'i-heroicons-banknotes',
    children: [
      { label: 'Cashflow Analysis', to: 'https://docs.google.com/spreadsheets/d/13TsO0y64pvv1mAbParQjK7AIIK0FxICc3CtBIQd3NTk/edit?gid=798759478#gid=798759478' },
      { label: 'Growth Capital', to: 'https://docs.google.com/spreadsheets/d/1FWjsPxQALgSIOeXEmuvbRqb-PiWhZtWCvjGyQXegTFY/edit?gid=147303761#gid=147303761' },
      { label: 'Go-to-Market Budgeting', to: 'https://docs.google.com/spreadsheets/d/1P0RhK4QYrTt9cqfCpf46qV1FnpH82N7CtlYkei3ISgU/edit?gid=1931257942#gid=1931257942' },
      { label: 'Public Sale, Launchpad', to: '/partner-schedule/legion' },
    ],
  },
  {
    label: 'Go-to-Market Partnership',
    icon: 'i-heroicons-globe-alt',
    children: [
      { label: 'Listing & Liquidity Academy', to: 'https://docsend.com/view/fexpeu4nxmvt8j72' },
      { label: 'Engage a Market Maker', to: '/engage-a-market-maker' },
      { label: 'Exchange Listing', to: '/exchange-listing' },
      { label: 'Marketing & KOL Support', to: 'https://docs.google.com/spreadsheets/d/1k7pyJe5x24wBnMET428rNqMTaKvPlsMqE1lZlLqeoC0/edit?gid=1326628848#gid=1326628848' },
      { label: 'Launch Checklist', to: 'https://docs.google.com/spreadsheets/d/1zo0zxfqXzvivroHi38jO0wHXLvpmRPO1dc6BRMMsB14/edit?usp=sharing' },
    ],
  },
  {
    label: 'Legal & Compliance',
    icon: 'i-heroicons-scale',
    children: [
      { label: 'Entity formation & incorporation', to: '/partner-schedule/cavenwell' },
      { label: 'External General Counsel', to: '/partner-schedule/cavenwell' },
      { label: 'Legal Opinion on Token', to: '/partner-schedule/cavenwell' },
    ],
  },
]

export const listedTokenNav: NavLink[] = [
  {
    label: 'Analysis & Insights',
    icon: 'i-heroicons-light-bulb',
    children: [
      { label: 'Market Maker Monitoring', to: '/market-maker-monitoring/start' },
      { label: 'Token Unlock Monitoring', to: '/token-unlocks' },
      { label: 'Volume, Liquidity & Price Performance', to: '/partner-activity-lead/volume-liquidity-price-performance' },
    ],
  },
  {
    label: 'Performance Optimization',
    icon: 'i-heroicons-chart-bar-square',
    children: [
      { label: 'Tokenomics & Demand Drivers', to: '/partner-activity-lead/tokenomics-demand-drivers' },
      // { label: 'Demand Driver Optimization', to: '/token-designer' },
      { label: 'AMM²', to: '/partner-activity-lead/amm-2' },
      { label: 'Smart Contract Development', to: '/partner-activity-lead/post-tge-smart-contract-protocol' },
      { label: 'Airdrop & Token Streaming', to: '/partner-schedule/magna' },
    ],
  },
  {
    label: 'Strategic Partnership',
    icon: 'i-heroicons-puzzle-piece',
    children: [
      { label: 'Listing & Liquidity Academy', to: 'https://docsend.com/view/fexpeu4nxmvt8j72' },
      { label: 'Engage a Market Maker', to: '/engage-a-market-maker' },
      { label: 'Exchange Listing', to: '/exchange-listing' },
      { label: 'Marketing & KOL Support', to: 'https://docs.google.com/spreadsheets/d/1k7pyJe5x24wBnMET428rNqMTaKvPlsMqE1lZlLqeoC0/edit?gid=1326628848#gid=1326628848' },
    ],
  },
  {
    label: 'Finance and Fundraising',
    icon: 'i-heroicons-banknotes',
    children: [
      { label: 'Cashflow Analysis', to: 'https://docs.google.com/spreadsheets/d/1xElL1SBH88T1xyxTCpne2qPfJy7vouoDp2UZOzsO18A/edit?gid=798759478#gid=798759478' },
      { label: 'Growth Capital', to: 'https://docs.google.com/spreadsheets/d/1FWjsPxQALgSIOeXEmuvbRqb-PiWhZtWCvjGyQXegTFY/edit?gid=147303761#gid=147303761' },
    ],
  },
  {
    label: 'Legal & Compliance',
    icon: 'i-heroicons-scale',
    children: [
      { label: 'External General Counsel', to: '/partner-schedule/cavenwell' },
      { label: 'Legal Opinion on Token', to: '/partner-schedule/cavenwell' },
    ],
  },
]
